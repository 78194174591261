<template>
  <div id="service">
    <v-toolbar flat>
      <v-spacer />

      <!-- Service CRUD Dialog -->
      <v-dialog v-model="service.crud.dialog" max-width="500px" @click:outside="closeServiceDialog" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="primary" elevation="0" v-bind="attrs" v-on="on" @click="addService()"><v-icon left>mdi-plus-circle</v-icon>サービス追加</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="">{{ formTitleService }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="service.crud.isFormValid">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-select :items="categories.items" item-text="name" item-value="id" v-model="service.crud.editedItem.point_mst_category_id" label="カテゴリ"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="service.crud.editedItem.service" class="required" label="サービス名" :rules="[inputRules.required]"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="service.crud.editedItem.point_content" class="required" outlined label="サービス説明" :rules="[inputRules.required]"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeServiceDialog()">キャンセル</v-btn>
            <v-btn v-promise-btn v-if="service.crud.isEdit" class="primary" @click="saveService()" :disabled="!service.crud.isFormValid">更新</v-btn>
            <v-btn v-promise-btn v-else class="primary" @click="saveService()" :disabled="!service.crud.isFormValid">追加</v-btn>
            <v-spacer></v-spacer>
              <!-- 確認用ダイアログ -->
              <confirm-dialog v-if="service.crud.isEdit" btnTitle="削除" @accept="deleteService">
                <template v-slot:title>このサービスを削除しますか？</template>
                <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
                <template v-slot:activator="{ on }">
                <v-btn icon v-on:click="on"><v-icon>mdi-delete-forever</v-icon></v-btn>
                </template>
              </confirm-dialog>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Category CRUD Dialog -->
      <v-dialog v-model="categories.crud.dialog" max-width="500px" @click:outside="closeCategoryDialog()">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" @click="addCategory()"><v-icon left>mdi-plus-circle</v-icon>カテゴリ追加</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="">{{ formTitleCategory }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="formCategories" v-model="categories.crud.isFormValid">
            <v-container>
              <v-row>
                <v-col cols="12" ><v-text-field v-model="categories.crud.editedItem.name" label="カテゴリ名" class="required" :rules="[inputRules.required]" /></v-col>
              </v-row>
            </v-container>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="" text @click="closeCategoryDialog()">キャンセル</v-btn>
            <v-btn v-promise-btn v-if="categories.crud.isEdit" class="primary" @click="saveCategory()" :disabled="!categories.crud.isFormValid">更新</v-btn>
            <v-btn v-promise-btn v-else class="primary" @click="saveCategory()" :disabled="!categories.crud.isFormValid">追加</v-btn>
            <v-spacer />
              <!-- 確認用ダイアログ -->
              <confirm-dialog v-if="categories.crud.isEdit" btnTitle="削除" @accept="deleteCategory">
                <template v-slot:title>このカテゴリを削除しますか？</template>
                <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。カテゴリに割り当てられているサービスは「未分類」となります。</template>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on:click="on"><v-icon>mdi-delete-forever</v-icon></v-btn>
                </template>
              </confirm-dialog>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-container v-if="loading">
      <div>
        <v-progress-linear
          indeterminate
          color="blue"
        ></v-progress-linear>
      </div>
    </v-container>

    <v-container v-else-if="!service.items.length">
      <info-box>サービスが登録されていません。<br><strong>カテゴリ追加</strong>からカテゴリを追加後、<strong>サービス追加</strong>からサービスを追加してください。</info-box>
    </v-container>

    <v-container v-else>
      <v-expansion-panels>
        <v-expansion-panel v-for="(cat,i) in categories.items" :key="i">
          <v-expansion-panel-header class="subtitle-1">
            <span>{{ cat.name }}<v-btn v-if="cat.id !== 0" icon small class="edit-category ml-2" @click.stop="editCategory(cat)"><v-icon small>mdi-pencil</v-icon></v-btn></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
              <v-data-table :headers="tableHeaders" :items-per-page="-1" :items="getItemByCategory(cat.id)" hide-default-header hide-default-footer no-data-text="サービスは登録されていません">
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="editService(item)">mdi-pencil</v-icon>
                </template>
                <template v-slot:item.point_content="{ item }">
                  {{ item.point_content }}
                </template>
              </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <message-bar ref="msgbar" />

  </div>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import MessageBar from '@/components/MessageBar.vue'
import InfoBox from '../../components/InfoBox.vue'

export default {
  components: { ConfirmDialog, MessageBar, InfoBox },
  name: 'service',
  data: function () {
    return {
      loading: true,
      tableHeaders: [
        { text: 'サービス名', value: 'service', cellClass: 'service-title' },
        { text: 'サービス説明', value: 'point_content', cellClass: 'service-explanation' },
        { text: 'アクション', value: 'actions', cellClass: 'actions', align: 'center' }
      ],
      categories: {
        items: [],
        crud: {
          dialog: false,
          isFormValid: false,
          isEdit: false,
          editedItem: {
            id: 0,
            name: ''
          }
        }
      },
      service: {
        items: [],
        crud: {
          dialog: false,
          isFormValid: false,
          isEdit: false,
          editedItem: {
            id: 0,
            point_mst_category_id: '',
            service: '',
            point_content: ''
          }
        }
      },
      snackbar: {
        sw: false,
        text: '',
        timeout: 0
      }
    }
  },
  methods: {
    getItemByCategory: function (id) {
      const filteredServices = this.service.items.filter(function (item, index) {
        return (item.point_mst_category_id === id)
      })
      return filteredServices
    },
    addService () {
      this.service.crud.isEdit = false
      this.service.crud.editedItem.id = 0
      this.service.crud.editedItem.point_mst_category_id = ''
      this.service.crud.editedItem.service = ''
      this.service.crud.editedItem.point_content = ''
      this.service.crud.dialog = true
    },
    editService (item) {
      this.service.crud.isEdit = true
      this.service.crud.editedItem.id = item.id
      this.service.crud.editedItem.point_mst_category_id = item.point_mst_category_id
      this.service.crud.editedItem.service = item.service
      this.service.crud.editedItem.point_content = item.point_content
      this.service.crud.dialog = true
    },
    saveService () {
      const _this = this
      const id = _this.service.crud.editedItem.id
      const formData = new FormData()
      formData.append('point_mst_category_id', _this.service.crud.editedItem.point_mst_category_id)
      formData.append('service', _this.service.crud.editedItem.service)
      formData.append('point_content', _this.service.crud.editedItem.point_content)
      if (_this.service.crud.isEdit) { // edit
        formData.append('_method', 'PUT')
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/point_mst/${id}`, formData)
            .then((res) => {
              _this.$store.commit('setFlashSuccess', 'サービスを変更しました。')
              resolve(res)
            })
            .catch(err => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(() => {
              _this.readDataFromAPI()
              _this.closeServiceDialog()
              _this.$forceUpdate() // HACK: 再度更新できない問題があるため強制リロード
            })
        })
      } else { // new
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/point_mst`, formData)
            .then((res) => {
              _this.$store.commit('setFlashSuccess', 'サービスを追加しました。')
              resolve(res)
            })
            .catch(err => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(() => {
              _this.readDataFromAPI()
              _this.closeServiceDialog()
              _this.$forceUpdate() // HACK: 再度更新できない問題があるため強制リロード
            })
        })
      }
    },
    deleteService () {
      const _this = this
      const id = _this.service.crud.editedItem.id
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/point_mst/${id}`)
        .then((res) => {
          _this.$store.commit('setFlashSuccess', 'サービスを削除しました。')
        })
        .catch(() => {
          _this.$store.commit('setFlashError', '入力内容に不備があります。入力内容をご確認ください。')
        })
        .finally(() => {
          _this.readDataFromAPI()
          _this.closeServiceDialog()
          _this.$forceUpdate() // HACK: 再度更新できない問題があるため強制リロード
        })
    },
    closeServiceDialog () {
      this.service.crud.dialog = false
      this.$nextTick(() => {
        // set default value
        Object.assign(this.service.crud, this.$options.data().service.crud)
        this.service.isFormValid = this.$refs.form?.validate() // HACK: バリデーション結果情報が更新されない
      })
      this.readDataFromAPI()
    },
    addCategory () {
      this.categories.crud.isEdit = false
      this.categories.crud.editedItem.id = 0
      this.categories.crud.editedItem.name = ''
      this.categories.crud.dialog = true
    },
    editCategory (item) {
      this.categories.crud.isEdit = true
      this.categories.crud.editedItem.id = item.id
      this.categories.crud.editedItem.name = item.name
      this.categories.crud.dialog = true
    },
    saveCategory () {
      const _this = this
      const id = _this.categories.crud.editedItem.id
      const formData = new FormData()
      formData.append('name', _this.categories.crud.editedItem.name)
      if (_this.categories.crud.isEdit) { // edit
        formData.append('_method', 'PUT')
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/point_mst_category/${id}`, formData)
            .then((res) => {
              _this.$store.commit('setFlashSuccess', 'カテゴリを変更しました。')
              resolve(res)
            })
            .catch(err => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(() => {
              _this.readDataFromAPI()
              _this.closeCategoryDialog()
              _this.$forceUpdate() // HACK: 再度更新できない問題があるため強制リロード
            })
        })
      } else { // new
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/point_mst_category`, formData)
            .then((res) => {
              _this.$store.commit('setFlashSuccess', 'カテゴリを追加しました。')
              resolve(res)
            })
            .catch(err => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(() => {
              _this.readDataFromAPI()
              _this.closeCategoryDialog()
              _this.$forceUpdate() // HACK: 再度更新できない問題があるため強制リロード
            })
        })
      }
    },
    deleteCategory () {
      const _this = this
      const id = _this.categories.crud.editedItem.id
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/point_mst_category/${id}`)
        .then(() => {
          _this.$store.commit('setFlashSuccess', 'カテゴリを削除しました。')
        })
        .catch(() => {
          _this.$store.commit('setFlashError', '入力内容に不備があります。入力内容をご確認ください。')
        })
        .finally(() => {
          _this.readDataFromAPI()
          _this.closeCategoryDialog()
          _this.$forceUpdate() // HACK: 再度更新できない問題があるため強制リロード
        })
    },
    closeCategoryDialog () {
      this.categories.crud.dialog = false
      this.$nextTick(() => {
        // set default value
        Object.assign(this.categories.crud, this.$options.data().categories.crud)
        this.categories.isFormValid = this.$refs.formCategories?.validate() // HACK: バリデーション結果情報が更新されない
      })
      this.readDataFromAPI()
    },
    readDataFromAPI: function () {
      this.loading = true
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/point_mst`)
        .then(res => {
          this.service.items = res.data
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/point_mst_category`)
        .then(res => {
          this.categories.items = res.data
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  computed: {
    formTitleService () {
      return this.service.crud.editedIndex === -1 ? 'サービス追加' : 'サービス編集'
    },
    formTitleCategory () {
      return this.categories.crud.editedIndex === -1 ? 'カテゴリ追加' : 'カテゴリ編集'
    }
  },
  created: function () {
    this.readDataFromAPI()
  }
}
</script>

<style scoped lang="scss">

  ::v-deep .service-title{
    font-weight: bold;
    width: 30%;
  }

  ::v-deep .service-explanation{
    padding: 5px!important;
  }

  ::v-deep .text-start{
    white-space: pre-line;
  }

  ::v-deep .actions{
    width: 1%;
  }

  .edit-category{
    display: none;
  }

  .v-expansion-panel--active{
    .edit-category{
      display: inline-block;
    }
  }

</style>
